<template>
  <div class="profile-data-wrapper financial-graphs earnings" v-show="!loading">
    <div class="profile-options">
      <div class="row">
        <div class="col">
          <router-link class="btn primary-btn" :to="{ name: 'FinancialGraphs' }"
            ><font-awesome-icon :icon="['fas', 'arrow-left']"
          /></router-link>
        </div>
        <div class="col flex-grow-1 d-flex justify-content-end">
          <Select2
            id="filter-period"
            :options="filters.period.options"
            v-model="filters.period.value"
            class="select2-box"
            :resetOptions="false"
            @select="getAvgEarnings()"
          />
        </div>
        <div class="col">
          <Select2
            id="filter-connect-types"
            v-model="filters.client.value"
            :options="filters.client.options"
            class="select2-box"
            :resetOptions="false"
            :placeholder="`Connect Type`"
            @select="getConnects()"
          />
        </div>
      </div>
    </div>
    <div class="row grid-2">
      <div class="col">
        <div class="earning-chart">
          <h2 class="section-heading text-transform-uppercase">
            Average Earnings ({{ filters.period.value }})
          </h2>
          <template
            v-if="
              !earningData ||
              earningData.reduce((partialSum, a) => partialSum + a.z, 0) === 0
            "
          >
            <div
              class="
                no-data-wrap
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <img src="@/assets/images/no-graph.png" alt="no-graph" />
              <div class="desc">No data<br />to show</div>
            </div>
          </template>
          <template v-else>
            <chart-js
              :type="`bar`"
              :datasets="earningChart.datasets"
              :labels="earningChart.labels"
              :options="earningChart.options"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="custom-table no-arrows">
      <table id="datatable">
        <thead>
          <tr>
            <th>Time Period</th>
            <th>Earnings</th>
            <th>Billable Resource</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(earningAvg, index) in earningAvgData" :key="index">
            <td>{{ earningAvg.x }}</td>
            <td>{{ $filters.currencyUSD(earningAvg.y.earning) }}</td>
            <td>{{ earningAvg.y.billable_resource }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="profile-footer d-flex justify-content-end">
      <button class="primary-btn" type="button" @click="downloadCsv()">
        <font-awesome-icon :icon="['fa', 'download']" /> Download
      </button>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faDownload,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
library.add(faChevronRight, faDownload, faArrowLeft);

import financialAvgEarningService from "../services/financialAvgEarning.service.js";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import Select2 from "../components/Select2.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import ChartJs from "../components/ChartJs.vue";
import common from "../mixins/common";

export default {
  components: { Select2, FontAwesomeIcon, ChartJs },
  mixins: [common],
  data() {
    return {
      loading: false,
      filters: {
        period: {
          options: ["Monthly", "Annually"],
          value: "Monthly",
        },
        client: {
          options: [],
          value: "",
        },
      },
      earningAvgData: [],
      
      earningChart: {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            borderColor: "#6fda45",
            backgroundColor: "#6fda45",
          },
        ],
        options: {
          layout: {
            padding: {
              top: 30,
              bottom: 30,
            },
          },
          indexAxis: "y",
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: false,
            title: false,
            datalabels: {
              backgroundColor: "transparent",
              borderRadius: 4,
              color: "#fff",
              font: {
                weight: "400",
                size: '16px "Montserrat", sans-serif',
              },
              padding: 0,
              formatter: function (value) {
                return "$"+`${Math.round(value.y)}`;
              },
            },
          },
        },
      },
    
    };
  },
  async mounted() {
    await this.getAvgEarnings();
    this.loading = false;
  },
  methods: {
    async getAvgEarnings() {
      let vm = this;
      try {
        vm.$store.commit("loadingStatus", true);
        const response = await financialAvgEarningService.get(vm.filters.period.value);
        vm.earningAvgData = response.avg_earning;
        /* Prepare data form bar chart */
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    downloadCsv: async function () {
      $(".buttons-csv").click();
    },
  },
};
</script>
